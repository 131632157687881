import React,{useState} from "react";
import Text from "../data-display/text"
import PostTaskWrapper from "../post-task/post-task-wrapper";
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from "@material-ui/core/styles/useTheme";
import { Task } from '../../models/task';
import {  WiTime4, IoIosFlashOff, AiOutlineCheckCircle, FiCalendar, AiOutlineCheck, AiOutlineCloseCircle, FaRegHandPointUp } from "react-icons/all";


const Fade = require("react-reveal/Fade");
const useStyles = makeStyles((theme) => ({
    container: {
         paddingTop: '24px',
    },
    PostTaskButton: {
         marginLeft: "12px",
         transition: "0.3s",
         borderRadius: "50px",
         cursor: "pointer",
         padding: "6px 14px",
         display: "flex",
         alignItems: "center",
         color: theme.palette.primary.main,
         "&:hover": {
              background: "rgba(78, 62, 253,0.13)"
         }
    },
}));



const EmptyTasksAsTasker = () => (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
         <img loading={"lazy"}  src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2FMicrosoftTeams-image%20(43).png?alt=media&token=29f6c3af-87a1-404c-a033-7b2940bbf459'} alt={"No Tasks for this task"} width={100} />
         <div style={{ height: "16px" }} />
         <Text style={{ color: "gray" }} bold variant={"body1"}>
              Find tasks that suit your skills!
         </Text>
         <Text style={{ color: "gray", padding: "0 35px" }} bold variant={"caption"}>
              {`Browse all available tasks and when you find one you like,
                      sell yourself with an offer.`}
         </Text>
    </div>
);

const EmptyTasksAsPoster = () => (
<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
          <img loading={"lazy"}  src={'https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fmy_tasks_owner_empty.png?alt=media&token=fed0995e-342f-4264-b9c7-9e43f3119c84'} alt={"No Tasks for this task"} width={80} />
          <div style={{ height: "16px" }} />
          <Text style={{ color: "gray" }} bold variant={"body1"}>
               Let's get your tasks posted!
          </Text>
          <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
               {`Let our taskers know what you need to get done,
                    and wait for offers to come in.`}
          </Text>
          <div style={{ height: "16px" }} />
          <PostTaskWrapper>
               <Text selectable={false} medium variant={"caption"}>
                    Post Task
               </Text>
          </PostTaskWrapper>
     </div>);

const EmptyFilters = () => {
     const isBrowser = typeof window !== 'undefined';
     const theme = useTheme();
     const [clicked, setClicked] = useState(0);
     const [tasksType, setType] = React.useState('all');
     const [posterTasks, setPosterTasks] = useState<Task[]>([]);
     const [taskerTasks, setTaskerTasks] = useState<Task[]>([]);

     React.useEffect(()=>{
          isBrowser && window.location.pathname.includes("/tasker") ? setClicked(1) : setClicked(0);
     },[window.location.pathname])
     return (<Fade bottom distance={"20px"} duration={400}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "24px 0", border: "1.5px solid rgb(240,240,240)", borderRadius: "6px" }}>
               {(() => {
                    switch (tasksType) {
                         case "all":
                              return (
                                   <div>
                                        {clicked === 1 && <EmptyTasksAsTasker />}
                                        {clicked === 0 && <EmptyTasksAsPoster />}
                                   </div >
                              );
                         case "expired":
                              return (posterTasks.filter(task => task.isExpired).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <FiCalendar size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You don't have any tasks That have expired.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "review":
                              return (posterTasks.filter(item => !item.isComplete && !item.isCancelled && !item.isAssigned && !item.isExpired).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <WiTime4 size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You don't have any tasks review.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "active":
                              return (posterTasks.filter(item => !item.isComplete && item.isAssigned && !item.isExpired && !item.isDispute && !item.isCancelled).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <IoIosFlashOff size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You don't have any active tasks.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "completed":
                              return (((clicked === 1 && taskerTasks.filter(item => item.isComplete).length <= 0) || (clicked === 0 && posterTasks.filter(item => item.isAssigned && !item.isCancelled && !item.isExpired && item.isComplete).length <= 0)) ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <AiOutlineCheckCircle size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You haven't completed any tasks recently.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "cancelled":
                              return (posterTasks.filter(item => item.isCancelled).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <AiOutlineCloseCircle size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You haven't cancelled any tasks recently.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "bidsAndOffers":
                              return (taskerTasks.filter(task => task.isExpired).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <FaRegHandPointUp size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             No Bids yet!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`Browse all available tasks and when ypu find one you like , sell yourself with an offer.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         case "assigned":
                              return (taskerTasks.filter(item => !item.isComplete && !item.isCancelled && !item.isExpired && item.isAssigned).length <= 0 ? <div>
                                   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                                        <IoIosFlashOff size={60} style={{ color: theme.palette.primary.main, margin: "auto 0px", marginLeft: "10px" }} />
                                        <div style={{ height: "16px" }} />
                                        <Text style={{ color: "gray" }} bold variant={"body1"}>
                                             Nothing here!
                                        </Text>
                                        <Text style={{ color: "gray", padding: "0 35px" }} bold medium variant={"caption"}>
                                             {`You don't have any tasks assigned to you right now.`}
                                        </Text>
                                   </div>
                              </div> : null
                              );
                         default:
                              break;
                    }
               })()}
          </div>
     </Fade>);
}




export  {EmptyTasksAsTasker,EmptyTasksAsPoster,EmptyFilters};