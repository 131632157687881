import React, { useState } from "react";
import Text from "../data-display/text"
import makeStyles from '@material-ui/core/styles/makeStyles';


import { WiTime4, FiCalendar, AiOutlineCheck, BiSortDown, AiOutlineCloseCircle, FaRegHandPointUp, TiFlashOutline } from "react-icons/all";


const useStyles = makeStyles((theme) => ({
     container: {
          paddingTop: '24px',
     },
     PostTaskButton: {
          marginLeft: "12px",
          transition: "0.3s",
          borderRadius: "50px",
          cursor: "pointer",
          padding: "6px 14px",
          display: "flex",
          alignItems: "center",
          color: theme.palette.primary.main,
          "&:hover": {
               background: "rgba(78, 62, 253,0.13)"
          }
     },

     drawerPaper: {
          width: 'calc(1280px/3)',
          left: 'calc((100% - 1280px)/2)',
          height: 'calc(100vh - 88px)',
          border: 'none',
          background: 'none',
          top: '88px',
     },
     card: {
          display: "flex",
          flexDirection: "column",
          background: "white",
          position: "relative",
          borderRadius: "6px",
          cursor: "pointer",
          boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
          transition: "0.3s",
          border: "1.5px solid rgb(245,245,245)",
          "&:hover": {
               boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)"
          }
     },
     selectedCard: {
          boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
          border: "1.5px solid rgb(220,220,220)"
     },
     formControl: {
          marginTop: '24px',
          minWidth: 120,
          width: '300px',
          background: 'transparent',
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
               border: '2px solid #d3d3d4',
               background: 'transparent',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
               border: '2px solid #d3d3d4',
               background: 'transparent',
          },
     }, statusIndicator: {
          borderRadius: '24px',
          padding: '1.5px 12px',
          paddingBottom: "3.5px",
          textAlign: 'center'
     },
     selectRoot: {
          padding: '12px',
          display: 'flex',
          alignItems: 'center',
     },
     listItemRoot: {
          minWidth: '40px',
     },
     dropdownStyle: {
          border: '2px solid #d3d3d4',
          boxShadow: 'none',
     },
     filtersContainer: {
          position: 'fixed',
          width: 'calc(1280px/3.1)',
          paddingBottom: '10px',
          background: theme.palette.background.default,
          zIndex: 1300,
          left: 'calc((100% - 1280px)/2)',
     },
     rootTabContainer: {
          transform: 'translateY(4px)',
          background: 'white',
          padding: '0 24px',
          borderRadius: '6px',
     },
     rootTab: {
          textTransform: 'none',
          padding: '0',
          minWidth: 'unset',
          justifyContent: 'flex-start !important',
          fontSize: theme.typography.pxToRem(15),
          marginRight: '48px',
     },
     indicatorTab: {
          display: 'flex',
          height: '4px',
          justifyContent: 'center',
          backgroundColor: theme.palette.primary.main,
          '& > div': {
               width: '100%',
               backgroundColor: theme.palette.primary.main,
          },
     }
}));



const StatusForFilters = ({handleChange,tasksType,showSelectedFilter,filterDialog }:{handleChange:(value:string)=>void,tasksType:string,showSelectedFilter:boolean,filterDialog:(value:boolean)=>void}) => {
     const classes = useStyles();
     const triggerFilterDialog = (val : boolean)=>{
          filterDialog(val);
     }
     if (showSelectedFilter) {
          switch (tasksType) {
               case "expired":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", background: "#f5005724", width: "110px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <FiCalendar size={18} style={{ color: "rgba(235, 80, 60, 0.9)", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "rgba(235, 80, 60, 0.9)", textAlign: "start", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Expired
                                   </Text>
                              </div>
                         </div>
                    );
               case "review":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", background: "#caf7ed", width: "110px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <WiTime4 size={18} style={{ color: "#3bb897", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "#3bb897", textAlign: "center", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Review
                                   </Text>
                              </div>
                         </div>
                    );
               case "active":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#e7e5fa", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px",width: "110px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <TiFlashOutline size={18} style={{ color: "#7365e6", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "#7365e6", textAlign: "center", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Active
                                   </Text>
                              </div>
                         </div>
                    );
               case "completed":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", backgroundColor: "#e7e5fa", width: "130px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <AiOutlineCheck size={18} style={{ color: "#7365e6", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "#7365e6", textAlign: "center", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Completed
                                   </Text>
                              </div>
                         </div>
                    );
               case "cancelled":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", background: "#f5005724", width: "120px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <AiOutlineCloseCircle size={18} style={{ color: "rgba(235, 80, 60, 0.9)", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "rgba(235, 80, 60, 0.9)", textAlign: "center", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Cancelled
                                   </Text>
                              </div>
                         </div>
                    );
               case "bidsAndOffers":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", background: "rgb(50 199 161)", backgroundColor: "#faf5e2", width: "100px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <FaRegHandPointUp size={18} style={{ color: "#e79829", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "#e79829", textAlign: "center" }}
                                        variant={'body2'} bold>
                                        Bids
                                   </Text>
                              </div>
                         </div>
                    );
               case "assigned":
                    return (
                         <div
                              onClick={() => { handleChange("all"); }}
                              style={{ display: "flex", cursor: "pointer", justifyContent: "flex-start", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "auto" }} >
                              <div style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#e7e5fa", flexDirection: "row", padding: "5px 0px", paddingLeft: "5px", paddingRight: "10px", width: "121px", wordBreak: "unset", }} className={classes.statusIndicator} >
                                   <TiFlashOutline size={18} style={{ color: "#7365e6", margin: "auto 0px", marginLeft: "10px" }} />
                                   <Text
                                        style={{ color: "#7365e6", textAlign: "center", marginLeft: "5px" }}
                                        variant={'body2'} bold>
                                        Assigned
                                   </Text>
                              </div>
                         </div>
                    );
               case "all":
                    return (
                         <div
                              onClick={()=>{filterDialog(true);} }
                              style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "110px" }} >
                              <BiSortDown
                                   style={{ margin: "auto", marginRight: "5px" }}
                                   fontSize="large"
                              />
                              <Text variant={'body2'} bold>
                                   Filters
                              </Text>
                         </div>
                    );
               default:
                    return null;

          }
     } else {
          return (<div
               onClick={()=>triggerFilterDialog(true)}
               style={{ display: "flex", cursor: "pointer", justifyContent: "center", flexDirection: "row", marginTop: "auto", marginBottom: "auto", marginLeft: "110px" }} >
               <BiSortDown
                    style={{ margin: "auto", marginRight: "5px" }}
                    fontSize="large"
               />
               <Text variant={'body2'} bold>
                    Filters
               </Text>
          </div>);
     }
}




export default StatusForFilters;