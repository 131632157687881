import React, { useEffect, useRef, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useMatch } from '@reach/router';
import Drawer from '@material-ui/core/Drawer';
import classNames from "classnames";
import makeStyles from '@material-ui/core/styles/makeStyles';
import TaskContainer from '../../components/task-view/task-container';
import TaskCard from '../../components/task-components/task-card';
import { TaskOffer } from "../../models/task-offer";
import { useParams, Router, useLocation } from "@reach/router";
import { updateAddress } from "../../redux/actions/user-actions";
import { auth, privateUsers } from "../../utils/firebase.utils";
import Address from "../../models/address";
import { Task } from '../../models/task';
import Text from '../../components/data-display/text';
import EditTask from "../../components/edit-task/edit-task-dialog";
import { firestore, tasks } from '../../utils/firebase.utils';
import DialogWrapper from "../../components/dialogs/dialog-wrapper";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/index.reducers';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserOffer, taskStatus } from "../../components/myTasks-components/helpers";
import StatusForFilters from "../../components/myTasks-components/statusForFilters";
import { EmptyFilters } from "../../components/myTasks-components/emptyTasks";
import TaskStatusIndicator from "../../components/myTasks-components/taskStatusIndicator"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import useTheme from "@material-ui/core/styles/useTheme";
import Collapse from "@material-ui/core/Collapse";
import { WiTime4, TiFlashOutline, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, FiCalendar, AiOutlineCheck, AiOutlineCloseCircle, FaRegHandPointUp } from "react-icons/all";
import LoadingTaskCard from "../../components/task-components/loading-task-card";
import { navigate } from 'gatsby';
import { Link } from '@reach/router';
import * as queryString from 'querystring';
import { Tabs } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { PrivateRoute } from '../../components/app-routes/app-routes';
import SEO from '../../components/seo/seo';
const Fade = require("react-reveal/Fade");
const useStyles = makeStyles((theme) => ({
     container: {
          paddingTop: '24px',
     },
     PostTaskButton: {
          marginLeft: "12px",
          transition: "0.3s",
          borderRadius: "50px",
          cursor: "pointer",
          padding: "6px 14px",
          display: "flex",
          alignItems: "center",
          color: theme.palette.primary.main,
          "&:hover": {
               background: "rgba(78, 62, 253,0.13)"
          }
     },

     drawerPaper: {
          transition: "0.3s",
          background: theme.palette.background.default,
          width: "calc(1280px/3)",
          left: "calc((100% - 1280px)/2)",
          height: "calc(100vh - 88px)",
          border: "none",
          top: "88px",
          zIndex: 60,
          [theme.breakpoints.down("md")]: {
               width: "calc(960px/(12/5))",
               left: "calc((100% - 960px)/2)"
          },
          [theme.breakpoints.down("sm")]: {
               width: "calc(100vw - 48px)",
               left: "24px"
          },
          [theme.breakpoints.down("xs")]: {
               width: "calc(100vw - 24px)",
               left: "12px"
          },
     },
     drawerPaperHidden: {
          transition: "0.3s",
          background: theme.palette.background.default,
          width: "calc(1280px/3)",
          left: "calc((100% - 1280px)/2)",
          height: "calc(100vh - 88px)",
          border: "none",
          top: "88px",
          [theme.breakpoints.down("md")]: {
               width: "calc(960px/(12/5))",
               left: "calc((100% - 960px)/2)"
          },
          [theme.breakpoints.down("sm")]: {
               width: "calc(100vw - 48px)",
               left: "24px"
          },
          [theme.breakpoints.down("xs")]: {
               width: "calc(100vw - 24px)",
               left: "12px"
          },
          transform: "translateX(-120%)"
     },


     card: {
          display: "flex",
          flexDirection: "column",
          background: "white",
          position: "relative",
          borderRadius: "6px",
          cursor: "pointer",
          boxShadow: "0 1px 2px rgb(0,0,0,0.1)",
          transition: "0.3s",
          border: "1.5px solid rgb(245,245,245)",
          "&:hover": {
               boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)"
          }
     },
     selectedCard: {
          boxShadow: "0 10px 20px -14px rgba(50,50,50,0.3)",
          border: "1.5px solid rgb(220,220,220)"
     },
     formControl: {
          marginTop: '24px',
          minWidth: 120,
          width: '300px',
          background: 'transparent',
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
               border: '2px solid #d3d3d4',
               background: 'transparent',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
               border: '2px solid #d3d3d4',
               background: 'transparent',
          },
     }, statusIndicator: {
          borderRadius: '24px',
          padding: '1.5px 12px',
          paddingBottom: "3.5px",
          textAlign: 'center'
     },
     selectRoot: {
          padding: '12px',
          display: 'flex',
          alignItems: 'center',
     },
     listItemRoot: {
          minWidth: '40px',
     },
     dropdownStyle: {
          border: '2px solid #d3d3d4',
          boxShadow: 'none',
     },
     filtersContainer: {
          position: 'fixed',
          // width: 'calc(1280px/3.1)',
          paddingBottom: '10px',
          background: theme.palette.background.default,
          zIndex: 1300,
          // left:  'calc((100% - 1280px)/2)',
     },
     rootTabContainer: {
          transform: 'translateY(4px)',
          background: 'white',
          padding: '0 24px',
          borderRadius: '6px',
     },
     rootTab: {
          textTransform: 'none',
          padding: '0',
          minWidth: 'unset',
          justifyContent: 'flex-start !important',
          fontSize: theme.typography.pxToRem(15),
          marginRight: '48px',
     },
     indicatorTab: {
          display: 'flex',
          height: '4px',
          justifyContent: 'center',
          backgroundColor: theme.palette.primary.main,
          '& > div': {
               width: '100%',
               backgroundColor: theme.palette.primary.main,
          },
     }
}));
const MyTasks = (props
     //      : {
     //      match?: match;
     //      history?: History;
     //      location?: Location;
     // }
) => {
     const sentinel = useRef<HTMLDivElement>(null);
     const [clicked, setClicked] = useState(0);
     const [posterTasks, setPosterTasks] = useState<Task[]>([]);
     const [taskerTasks, setTaskerTasks] = useState<Task[]>([]);
     const [taskerOffers, setTaskerOffers] = useState<Task[]>([]);
     const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
     const [openFilters, setFilterDialog] = useState(false);
     const [loading, setLoading] = useState(true);
     const [openExpiredTasks, setOpenExpiredTasks] = useState(false);
     const [openBidsTasks, setOpenBidsTasks] = useState(false);
     const [showSelectedFilter, setShowSelectedFilter] = useState(false);
     const [tasksOffersAvatars, setTasksOffersAvatars] = useState<{ taskId: string, avatars: { name: string; profilePicture: string }[], taskOffer: TaskOffer }[]>([]);
     const { userData, addressLoading, defaultTaskAddressID } = useSelector((state: RootState) => state.userData);
     const params = useParams();
     const MYTASKS_PATH_MATCH = '/my-tasks/:type/:id/*';
     const dispatch = useDispatch();
     const [tasksType, setType] = React.useState('all');
     const theme = useTheme();
     const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
     const mdUp = useMediaQuery(theme.breakpoints.up("md"));
     const classes = useStyles();
     const user = auth?.currentUser;
     const mytasksMatch = useMatch(MYTASKS_PATH_MATCH)
     useEffect(() => {
          if (user) {
               getOwnerTasks();
          }
     }, [posterTasks])

     const fetchUserAvatars = async (offerUIDs) => {
          let users = firestore.collection('users');
          let avatarsList = await users.where("uid", "in", offerUIDs?.length <= 3 ? offerUIDs : offerUIDs?.slice(0, 3)).get();
          let usersAvatars: { name: string; profilePicture: string; }[] = [];
          for (let index = 0; index < avatarsList.docs.length; index++) {
               const element = avatarsList.docs[index].data();
               usersAvatars.push({
                    name: (element["firstName"] ?? "") + " " + (element["lastName"] ?? ""),
                    profilePicture: element["profilePicture"] ?? "",
               });
          }
          return usersAvatars;
     };


     const poster = () => {
          navigate('/my-tasks/poster');
     }


     const tasker = () => {
          navigate('/my-tasks/tasker');
     }


     const handleChangeIndicator = (event, newValue) => {
          if (newValue == 0) {
               setType('all');
               poster();
               // navigate('/my-tasks/poster');
          } else if (newValue == 1) {
               setType('all');
               tasker();
               // navigate('/my-tasks/tasker');
          }
          setClicked(newValue);
     };

     const handleChange = (value) => {
          if (value === "all") {
               setShowSelectedFilter(false);
          } else {
               setShowSelectedFilter(true);
          }
          if (clicked === 0) {
               setFilterDialog(false);
               //     navigate(
               //           `/my-tasks/poster?filter=${value as string}`,
               //      );
          }
          else {
               setFilterDialog(false);
               // navigate(
               //      `/my-tasks/tasker?filter=${value as string}`,
               // );
          }
          if (value === "expired") {
               setOpenExpiredTasks(true);
          } else if (value === "bidsAndOffers") {
               setOpenBidsTasks(true);
          } else {
               setOpenExpiredTasks(false);
               setOpenBidsTasks(false);
          }
          setType(value as string);
     };

     useEffect(() => {
          const query = queryString.parse(props?.location?.search);
          const path = props?.location?.pathname;
          const userType = mytasksMatch?.type;
          if (userType === "poster" || userType === "tasker") {
               if (
                    path == `/my-tasks/poster/${mytasksMatch?.id}` ||
                    path == `/my-tasks/poster`
               ) {
                    setClicked(0);
                    if (!(Object.keys(query).length === 0 && query.constructor === Object)) {
                         if (query['?filter'] == 'all') setType('all');
                         else if (query['?filter'] == 'completed') setType('completed');
                         else if (query['?filter'] == 'cancelled') setType('cancelled');
                    } else setType('all');
               } else if (
                    path == `/my-tasks/tasker/${mytasksMatch?.id}` ||
                    path == `/my-tasks/tasker`
               ) {
                    setClicked(1);
                    if (!(Object.keys(query).length === 0 && query.constructor === Object)) {
                         if (query['?filter'] == 'assigned') setType('assigned');
                         else if (query['?filter'] == 'completed') setType('completed');
                         else if (query['?filter'] == 'bidsAndOffers') setType('bidsAndOffers');
                    } else setType('all');
               }
          } else {
               // navigate(`/my-tasks/poster`);
          }
     }, [props.location]);

     useEffect(() => {
          (async () => {
               setLoading(true);
               if (clicked === 0 && user) {
                    await getOwnerTasks();
               } else if (clicked === 1 && user) {
                    await getWorkerTasks();
                    await getWorkerOffers();
               }
               setLoading(false);
          })()
     }, [clicked]);


     const getOwnerTasks = async () => {
          let d = new Date();
          let minDate = new Date(d.setDate(d.getDate() - 60));
          let userId = userData?.uid;
          let tasksRef = firestore.collection('tasks');
          let tasks: Task[] = [];
          let OffersAvatarsList = [];
          const tasksList = await tasksRef.where("uid", "==", userId)
               .where("dateToStart", ">", minDate)
               .orderBy("dateToStart", "desc")
               .get();
          for (let index = 0; index < tasksList.docs.length; index++) {
               let element = tasksList.docs[index].data();
               element.id = tasksList.docs[index].id;
               const status = taskStatus(element, userData?.uid);
               if (status === "review-offers") {
                    let offer = await getUserOffer(element?.id, userData?.uid);
                    let avatarsList = await fetchUserAvatars(element?.offerUIDs);
                    OffersAvatarsList.push({ taskId: element?.id, avatars: avatarsList, taskOffer: offer });
               }
               tasks.push(new Task(element));
          }
          if (tasksType == "completed" && posterTasks.findIndex(item => item.isAssigned && !item.isCancelled && !item.isExpired && item.isComplete) >= 0) {
               posterTasks.sort((a, b) => {
                    if (a.completeDate < b.completeDate) {
                         return 1;
                    } else {
                         return -1;
                    }
               })
               setPosterTasks(posterTasks);
          } else {
               setPosterTasks(tasks);
          }
          setTasksOffersAvatars(OffersAvatarsList);
          if (addressLoading && tasks.length <= 0) {
               privateUsers
                    .doc(userData?.uid)
                    .get()
                    .then(async (privateDoc) => {
                         let data = privateDoc.data();
                         let addressList: Address[] = [];
                         let addressSnapshots = await privateUsers.doc(userData?.uid).collection("addresses").get();
                         if (addressSnapshots.size > 0) {
                              addressSnapshots.forEach((doc) => {
                                   let addressData = doc.data();
                                   addressData.id = doc.id;
                                   addressList.push(new Address(addressData));
                              });
                         }
                         dispatch(
                              updateAddress({
                                   addresses: addressList,
                                   defaultTaskAddress: data?.defaultTaskAddress?.toString() ?? "",
                                   defaultBillingAddress: data?.defaultBillingAddress?.toString() ?? ""
                              })
                         );
                    });
          }
     };
     const getWorkerTasks = async () => {
          let d = new Date();
          let minDate = new Date(d.setDate(d.getDate() - 14));
          let userId = userData?.uid;
          let tasksRef = firestore.collection('tasks');
          let workerTasksList: Task[] = [];
          let OffersAvatarsList = [];
          const firstTasksList = await tasksRef.where("assignedWorker.uid", "==", userId)
               .where("isComplete", "==", false)
               .orderBy("dateToStart", "desc")
               .get();
          for (let index = 0; index < firstTasksList.docs.length; index++) {
               const element = new Task(firstTasksList.docs[index].data());
               if (element) {
                    const status = taskStatus(element, userData?.uid);
                    if (status === "has-offer") {
                         let offer = await getUserOffer(element?.id, userData?.uid);
                         let avatarsList = await fetchUserAvatars(element?.offerUIDs);
                         OffersAvatarsList.push({ taskId: element?.id, avatars: avatarsList, taskOffer: offer });
                    }
                    workerTasksList.push(element);
               }
          }
          const secondTasksList = await tasksRef
               .where("assignedWorker.uid", "==", userId)
               .where("isComplete", "==", true)
               .where("dateToStart", ">", minDate)
               .orderBy("dateToStart")
               .get();
          for (let index = 0; index < secondTasksList.docs.length; index++) {
               const element = new Task(secondTasksList.docs[index].data());
               const elementHasIndex = workerTasksList.findIndex(item => item?.id === element?.id);
               if (element && elementHasIndex === -1) {
                    const status = taskStatus(element, userData?.uid);
                    if (status === "has-offer") {
                         let offer = await getUserOffer(element?.id, userData?.uid);
                         let avatarsList = await fetchUserAvatars(element?.offerUIDs);
                         OffersAvatarsList.push({ taskId: element?.id, avatars: avatarsList, taskOffer: offer });
                    }
                    workerTasksList.push(element);
               }
          }
          const thirdTasksList = await tasksRef
               .where("offerUIDs", "array-contains", userId)
               .where("isComplete", "==", false)
               .where("isCancelled", "==", false)
               .where("isAssigned", "==", false)
               .where("dateToStart", ">", d)
               .orderBy("dateToStart")
               .get();
          for (let index = 0; index < thirdTasksList.docs.length; index++) {
               const element = new Task(thirdTasksList.docs[index].data());
               const elementHasIndex = workerTasksList.findIndex(item => item?.id === element?.id);
               if (element && elementHasIndex === -1) {
                    const status = taskStatus(element, userData?.uid);
                    if (status === "has-offer") {
                         let offer = await getUserOffer(element?.id, userData?.uid);
                         let avatarsList = await fetchUserAvatars(element?.offerUIDs);
                         OffersAvatarsList.push({ taskId: element?.id, avatars: avatarsList, taskOffer: offer });
                    }
                    workerTasksList.push(element);
               }
          }
          if(tasksType=="completed" &&   taskerTasks.findIndex(item => item.isComplete) >= 0 ){
               taskerTasks.sort((a, b) => {
                    if (a.completeDate < b.completeDate) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                setTaskerTasks(taskerTasks);
          }else{
            setTaskerTasks(workerTasksList);
          }
          setTasksOffersAvatars(OffersAvatarsList);
     };
     const getWorkerOffers = async () => {
          let userId = userData?.uid;
          let workerOffersRef = firestore.collection('tasks');
          await workerOffersRef
               .where('offerUIDs', 'array-contains', userId)
               .where('isAssigned', '==', false)
               .where('isCancelled', '==', false)
               .where('isComplete', '==', false)
               .where('dateToStart', '>', new Date())
               .get()
               .then((querySnapshot) => {
                    if (querySnapshot.docs.length > 0) {
                         let tasks: Task[] = [];
                         querySnapshot.docs.map((doc) => {
                              let task = doc.data();
                              task.id = doc.id;
                              tasks.push(new Task(task));
                         });
                         setTaskerOffers(tasks);
                    } else setTaskerOffers([]);
               });
     };

     const taskEdit = (value) => {
          setTaskToEdit(value)
     }
     const renderTabs = () => {
          return (
               <Tabs
                    variant="scrollable"
                    scrollButtons="off"
                    classes={{
                         root: classes.rootTabContainer,
                         indicator: classes.indicatorTab,
                    }}
                    TabIndicatorProps={{ children: <div /> }}
                    value={clicked}
                    onChange={handleChangeIndicator}
                    aria-label="simple tabs example"
               >
                    <Tab
                         disableRipple
                         classes={{ root: classes.rootTab }}
                         label={
                              <Text variant={'body2'} bold>
                                   Poster
                              </Text>
                         }
                    />
                    <Tab
                         disableRipple
                         classes={{ root: classes.rootTab }}
                         label={
                              <Text variant={'body2'} bold>
                                   Tasker
                              </Text>
                         }
                    />
                    {/* <StatusForFilters /> */}
                    <StatusForFilters handleChange={value => handleChange(value)} tasksType={tasksType} showSelectedFilter={showSelectedFilter} filterDialog={(value) => setFilterDialog(value)} />

               </Tabs>
          );
     };

     const renderSelectMenu = () => {
          return (
               <Dialog disableEscapeKeyDown PaperProps={{ style: { width: "300px", paddingTop: "10px", paddingBottom: "10px" } }} open={openFilters} onClose={() => { setFilterDialog(false); }}>
                    <DialogContent>
                         {clicked === 0 ? (
                              <div>
                                   <MenuItem onClick={() => { handleChange('expired'); }}>
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <FiCalendar size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Expired Tasks</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('review'); }}>
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <WiTime4 size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Awaiting Review</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('active'); }}>
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <TiFlashOutline size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Active Tasks</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('completed'); }} >
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <AiOutlineCheck size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Recently Completed</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('cancelled'); }} >
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <AiOutlineCloseCircle size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Recently Cancelled</Text>
                                   </MenuItem>
                              </div>
                         ) : (
                              <div>
                                   <MenuItem onClick={() => { handleChange('bidsAndOffers'); }} >
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <FaRegHandPointUp size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Pending Bids & Offers</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('assigned'); }} >
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <TiFlashOutline size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Assigned Tasks</Text>
                                   </MenuItem>
                                   <MenuItem onClick={() => { handleChange('completed'); }} >
                                        <ListItemIcon classes={{ root: classes.listItemRoot }}>
                                             <AiOutlineCheck size={20} />
                                        </ListItemIcon>
                                        <Text variant={'caption'}>Recently Completed</Text>
                                   </MenuItem>
                              </div>
                         )}
                    </DialogContent>
               </Dialog>
          );
     };

     const taskCardWrapper = (key, toPath, task) => {
          return (
               <div key={key}>
                    <div

                         onClick={() => { navigate(toPath) }}
                    >
                         <TaskCard
                              task={task}
                              selected={mytasksMatch?.id}
                              hideStatus
                         />
                    </div>
                    {taskStatus(task, userData?.uid) !== "disputed-task" && taskStatus(task, userData?.uid) !== "completed-tasker" &&
                         <div className={classNames({
                              [classes.card]: true,
                              [classes.selectedCard]: mytasksMatch?.id === task?.id ? true : false
                         })} style={{ marginTop: "-4px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", height: "40px", background: "rgb(252 252 252)" }}>
                              {user && <TaskStatusIndicator tasksOffersAvatars={tasksOffersAvatars} task={task} taskEdit={taskEdit} />}
                         </div>
                    }
                    <div style={{ height: '16px' }} />
               </div>
          )
     }

     return (
          <>
               <SEO title={'Sydetasker - Post. Hire. Done.'} pathname={"/my-tasks"} description={'Any task you need done? Sydetasker easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, yard work, & more.'} />
               <Container fixed={mdUp} className={classes.container} style={{ background: !mdUp ? "white" : "" }} maxWidth={"lg"}>
                    <DialogWrapper
                         onClose={() => {
                              navigate(`/my-tasks/poster/${taskToEdit?.id}`);
                         }}
                         open={taskToEdit !== null && taskToEdit && mytasksMatch && mytasksMatch["*"] === "edit"}
                         root={true}
                         title={"Edit Task"}
                    >
                         <EditTask task={taskToEdit} path={"my-tasks"} />
                    </DialogWrapper>
                    <Drawer
                         variant="permanent"
                         classes={{
                              paper: mdUp || (!mdUp && !mytasksMatch?.id) ? classes.drawerPaper : classes.drawerPaperHidden
                         }}
                         PaperProps={{
                              id: 'browse-tasks',
                         }}
                         anchor="left"
                    >

                         {!loading && <div className={classes.filtersContainer} style={{ left: !xsDown ? 'calc((100% - 1280px)/2)' : "", width: xsDown ? "inherit" : 'calc(1280px/3.1)', }}>
                              {renderTabs()}
                              <div style={{ width: "16px" }} />
                              {renderSelectMenu()}
                              {clicked === 0 && posterTasks.filter(task => task.isExpired).length > 0 && <div style={{ width: '16px', height: "16px" }} />}
                              {clicked === 0 && posterTasks.filter(task => task.isExpired).length > 0 &&
                                   <div style={{ width: '16px', height: "16px" }} /> &&
                                   <div onClick={() => {
                                        if (openExpiredTasks && tasksType === "expired") {
                                             handleChange("all");
                                        }
                                        setOpenExpiredTasks(!openExpiredTasks);
                                   }}
                                        style={{ display: "flex", cursor: "pointer", alignItems: "center", background: "rgba(500, 0, 0, 0.05)", borderRadius: "8px", padding: "8px 14px", }}>
                                        <Text variant={"caption"} medium style={{ height: "100%", color: "rgb(198,53,89)", marginBottom: "2px", minHeight: "12px", textTransform: "none" }} >
                                             {posterTasks.filter(task => task?.isExpired).length} tasks have expired
                                        </Text>
                                        {!openExpiredTasks ?
                                             <IoIosArrowDropdownCircle size={20} style={{ color: "rgb(198,53,89)", margin: "auto 10px auto auto", }} /> :
                                             <IoIosArrowDropupCircle size={20} style={{ color: "rgb(198,53,89)", margin: "auto 10px auto auto", }} />
                                        }

                                   </div>
                              }
                              {clicked === 1 && taskerOffers?.length > 0 && <div style={{ width: '16px', height: "16px" }} />}
                              {clicked === 1 && taskerOffers?.length > 0 &&
                                   <div onClick={() => {
                                        if (openBidsTasks && tasksType === "bidsAndOffers") {
                                             handleChange("all");
                                        }
                                        setOpenBidsTasks(!openBidsTasks);
                                   }}
                                        style={{ display: "flex", cursor: "pointer", alignItems: "center", background: "rgb(50 199 161)", backgroundColor: "#faf5e2", borderRadius: "8px", padding: "8px 14px", }}>
                                        <Text variant={"caption"} medium style={{ color: "#e79829", marginBottom: "2px", minHeight: "12px", textTransform: "none" }} >
                                             {taskerOffers.length} Pending Bids
                                        </Text>
                                        {!openBidsTasks ?
                                             <IoIosArrowDropdownCircle size={20} style={{ color: "#e79829", margin: "auto 10px auto auto", }} /> :
                                             <IoIosArrowDropupCircle size={20} style={{ color: "#e79829", margin: "auto 10px auto auto", }} />
                                        }
                                   </div>
                              }
                              <div style={{ width: '16px' }} />
                         </div>}
                         {!loading && <div
                              style={{
                                   display: 'inline-block',
                                   width: 'calc(100% - 18px)',
                                   marginTop: (clicked === 1 && taskerOffers?.length > 0) || (clicked === 0 && posterTasks.filter(task => task.isExpired).length > 0) ? '112px' : "72px",
                              }}
                         >
                              <Collapse in={openExpiredTasks} style={{ width: "100%" }}>
                                   {posterTasks.length >= 1 && clicked === 0 && (tasksType == 'expired' || tasksType == 'all') &&
                                        posterTasks.findIndex(item => item.isExpired) >= 0 ?
                                        <div>
                                             {posterTasks.map((task, index) => {
                                                  if (task.isExpired)
                                                       return (
                                                            taskCardWrapper(
                                                                 `task_${task.id + "_" + index}`,
                                                                 `/my-tasks/poster/${task.id}?filter=${tasksType}`,
                                                                 task
                                                            )
                                                       );
                                             })}
                                             <div style={{ height: '16px' }} />
                                        </div> : tasksType == 'expired' ? < EmptyFilters /> : null
                                   }
                              </Collapse>

                              {posterTasks.length >= 1 && clicked === 0 && (tasksType == 'review' || tasksType == 'all')
                                   && posterTasks.findIndex(item => !item.isComplete && !item.isCancelled && !item.isAssigned && !item.isExpired) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Awaiting Review
                                        </Text>
                                        {posterTasks.map((task, index) => {
                                             if (!task.isComplete &&
                                                  !task.isAssigned && !task.isCancelled &&
                                                  !task.isExpired)
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/poster/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'review' ? < EmptyFilters /> : null
                              }
                              {posterTasks.length >= 1 && clicked === 0 && (tasksType == 'active' || tasksType == 'all') &&
                                   posterTasks.findIndex(item => !item.isComplete && item.isAssigned && !item.isExpired && !item.isDispute && !item.isCancelled) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Active Tasks
                                        </Text>
                                        {posterTasks.map((task, index) => {
                                             if (!task.isCancelled && !task.isComplete && !task.isExpired && !task.isDispute && task.isAssigned)
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/poster/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'active' ? < EmptyFilters /> : null
                              }
                              {posterTasks.length >= 1 && clicked === 0 && (tasksType == 'completed' || tasksType == 'all')
                                   && posterTasks.findIndex(item => item.isAssigned && !item.isCancelled && !item.isExpired && item.isComplete) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Recently Completed
                                        </Text>
                                        {posterTasks.map((task, index) => {
                                             if (task.isAssigned && !task.isCancelled && !task.isExpired && task.isComplete) {
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/poster/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                             }
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'completed' && clicked === 0 ? < EmptyFilters /> : null
                              }
                              {posterTasks.length >= 1 && clicked === 0 && (tasksType == 'cancelled' || tasksType == 'all')
                                   && posterTasks.findIndex(item => item.isCancelled) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Cancelled Tasks
                                        </Text>
                                        {posterTasks.map((task, index) => {
                                             if (task.isCancelled || task.isDispute) {
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/poster/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                             }
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'cancelled' ? < EmptyFilters /> : null
                              }
                              {
                                   clicked === 0 && posterTasks.length <= 0 && tasksType == 'all' && < EmptyFilters />
                              }
                              <Collapse in={openBidsTasks} style={{ width: "100%" }}>
                                   {taskerTasks.length >= 1 && clicked === 1 && (tasksType == 'bidsAndOffers' || tasksType == 'all') &&
                                        taskerTasks.length >= 1 ?
                                        <div>
                                             {taskerOffers.map((task, index) => {
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/tasker/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                             })}
                                             <div style={{ height: '16px' }} />
                                        </div> : tasksType == 'bidsAndOffers' ? < EmptyFilters /> : null
                                   }
                              </Collapse>
                              {taskerTasks.length >= 1 && clicked === 1 && (tasksType == 'assigned' || tasksType == 'all') &&
                                   taskerTasks.findIndex(item => !item.isComplete && !item.isCancelled && !item.isExpired && item.isAssigned) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Assigned Tasks
                                        </Text>
                                        {taskerTasks.map((task, index) => {
                                             if (!task.isComplete && !task.isDispute && !task.isCancelled && !task.isExpired && task.isAssigned) {
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/tasker/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                             }
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'assigned' ? < EmptyFilters /> : null
                              }
                              {taskerTasks.length >= 1 && clicked === 1 && (tasksType == 'completed' || tasksType == 'all') &&
                                   taskerTasks.findIndex(item => item.isComplete) >= 0 ?
                                   <div>
                                        <Text
                                             variant={'body2'}
                                             bold
                                             style={{ color: 'black', marginLeft: "10px", marginBottom: "5px" }}
                                        >
                                             Recently Completed
                                        </Text>
                                        {taskerTasks.map((task, index) => {
                                             if (task.isComplete) {
                                                  return (
                                                       taskCardWrapper(
                                                            `task_${task.id + "_" + index}`,
                                                            `/my-tasks/tasker/${task.id}?filter=${tasksType}`,
                                                            task
                                                       )
                                                  );
                                             }
                                        })}
                                        <div style={{ height: '16px' }} />
                                   </div> : tasksType == 'completed' && clicked === 1 ? < EmptyFilters /> : null
                              }
                              {
                                   clicked === 1 && taskerTasks.length <= 0 && tasksType == 'all' && <EmptyFilters />
                              }

                              <div ref={sentinel} />
                         </div>}
                         {
                              loading && Array.from(new Array(9)).map((i, innerIndex) => (
                                   <div key={" " + innerIndex}>
                                        <LoadingTaskCard index={i} />
                                        <div style={{ height: "16px" }} />
                                   </div>
                              ))
                         }
                    </Drawer>
                    {!xsDown && <Grid
                         container
                         direction="row"
                         justify="flex-end"
                         alignItems="center"
                         spacing={0}
                    >
                         <Grid item md={8} style={{}}>
                              <TaskContainer matchPath={MYTASKS_PATH_MATCH} currentPath={"my-tasks"} />
                         </Grid>
                    </Grid>}

                    {xsDown && <Grid container alignItems="center" spacing={2}>
                         <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                              <TaskContainer matchPath={MYTASKS_PATH_MATCH} currentPath={"my-tasks"} />
                         </Grid>
                    </Grid>}
               </Container>
          </>
     );
};




const App = () => {
     const location = useLocation();
     return (
          <div className="content">
               <Router location={location}>
                    <PrivateRoute path={"/my-tasks/*"} component={MyTasks} />
               </Router>
          </div>
     )
}

export default App;

